<template>
  <v-dialog
    v-model="openedDialog"
    max-width="1100"
  >
    <v-card>
      <v-card-title>
        Añadir una entrada al almacén
      </v-card-title>
      <add-inputs-form @close="openedDialog = false" />
    </v-card>
  </v-dialog>
</template>

<script>
  import AddInputsForm from './AddInputsForm.vue'

  export default {
    name: 'AddProductType',
    components: { AddInputsForm },
    props: {
      value: {
        type: Boolean,
        default () {
          return false
        },
      },
    },
    computed: {
      openedDialog: {
        get () {
          return this.value
        },
        set (v) {
          this.$emit('input', v)
        },
      },
    },
  }
</script>
