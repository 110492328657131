<template>
  <v-row justify="space-around">
    <v-col cols="auto">
      <v-dialog
        v-model="dialog"
        max-width="1000"
        @click:outside="close"
        @keydown.esc="close"
      >
        <v-card>
          <v-toolbar
            color="primary"
            dark
          >
            Agregar Proveedor
          </v-toolbar>
          <v-card-text>
            <v-container>
              <v-row
                justify="center"
              >
                <v-col
                  cols="12"
                >
                  <v-text-field
                    v-model="form.name"
                    required
                    class="purple-input"
                    label="Nombre*"
                    type="text"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <v-text-field
                    v-model="form.email"
                    required
                    class="purple-input"
                    label="Email"
                    type="Email"
                    name="email"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                  lg="6"
                >
                  <vue-tel-input-vuetify
                    v-model="form.phone"
                    label="Teléfono 1*"
                    return-masked-value
                    placeholder="0000-0000000"
                    outlined
                  />
                </v-col>
                <v-col
                  cols="12"
                >
                  <v-textarea
                    v-model="form.direction"
                    outlined
                    name="address"
                    label="Direccion"
                    no-resize
                  />
                </v-col>
                <small>Los campos indicados con <span class="red--text">(*)</span> son requeridos</small>
              </v-row>
            </v-container>
          </v-card-text>
          <v-card-actions class="justify-end">
            <v-btn
              text
              @click="close"
            >
              Cerrar
            </v-btn>
            <v-btn
              color="secondary"
              :disabled="disabled"
              :loading="fetchingProviders"
              @click="save"
            >
              Agregar
            </v-btn>
          </v-card-actions>
        </v-card>
      </v-dialog>
    </v-col>
  </v-row>
</template>

<script>
  import { mapActions, mapState } from 'vuex'
  export default {
    name: 'NewProviderDialog',

    props: {
      dialog: {
        type: Boolean,
        default: () => true,
      },
    },

    data () {
      return {
        typesCi: ['V', 'E', 'P', 'J', 'G'],
        ciType: 'V',
        form: {
          name: '',
          email: '',
          phone: '',
          direction: '',
        },
      }
    },

    computed: {
      ...mapState([
        'fetchingProviders',
      ]),

      disabled () {
        return !(this.form.name && this.form.phone)
      },
    },

    // watch: {
    //   data (v) {
    //     this.ciType = v?.ciType
    //     this.form.ci = v?.ci
    //   },
    // },

    methods: {
      ...mapActions([
        'createProvider',
      ]),

      close () {
        this.$emit('close')
      },

      async save () {
        // this.form.ci = this.ciType + '-' + this.form.ci
        // // // // // // // // // // // // console.log('nuevo proveedor', this.form)
        await this.createProvider(this.form)
        // this.$emit('search', this.form.ci)
        this.form = {
          name: '',
          email: '',
          phone: '',
          direction: '',
        }

        this.close()
      },
    },
  }
</script>
