<template>
  <v-container
    fluid
    tag="section"
  >
    <!-- <pre>{{ warhouseInputs }}</pre> -->
    <!-- <pre>{{ item }}</pre> -->
    <v-row justify="center">
      <v-col cols="12">
        <base-material-card
          icon="mdi-arrow-down-bold"
          title="Entradas"
          class="px-5 py-3"
          color="orange"
        >
          <v-data-table
            :item-key="'_id'"
            :search="search"
            :headers="headers"
            :items="inputs"
            :loading="fetchingWarehouseInputs"
            loading-text="Cargando... Por favor, espere"
            no-data-text="No hay datos disponibles"
            :footer-props="{
              'items-per-page-text': 'Filas por página',
              pageText: '{0}-{1} de {2}'
            }"
          >
            <template
              v-if="showAdd"
              v-slot:[`body.prepend`]="{}"
            >
              <br>
              <tr>
                <th />
                <th>
                  <template
                    @click="dialogA = true"
                  >
                    <v-autocomplete
                      v-model="data.provider"
                      :items="getProviders"
                      item-text="name"
                      return-object
                      :loading="fetchingProviders"
                      :disabled="fetchingProviders"
                      outlined
                      no-data-text="No hay datos disponibles"
                      label="Proveedor"
                    />
                  </template>
                  <!-- <v-row justify="center">
                    <v-col class="text-center">
                      <v-btn
                        fab
                        dark
                        small
                        :color="color"
                        @click="dialog = true"
                      >
                        <v-icon dark>
                          mdi-plus
                        </v-icon>
                      </v-btn>
                    </v-col>
                    <v-col class="text-center">
                      <v-btn
                        fab
                        dark
                        small
                        :color="color"
                        @click="dialogA = true"
                      >
                        <v-icon dark>
                          mdi-magnify
                        </v-icon>
                      </v-btn>
                    </v-col>
                  </v-row> -->
                  <!-- <new-provider-dialog
                    :dialog="dialog"
                    @close="dialog = false"
                  />

                  <search-providers-dialog
                    :dialog="dialogA"
                    @select="select"
                    @close="dialogA = false"
                  /> -->
                </th>
                <th colspan="2">
                  <v-autocomplete
                    v-model="productSelected"
                    label="Productos"
                    :loading="fetchingProducts"
                    :disabled="fetchingProducts"
                    :items="getProducts"
                    item-text="name"
                    no-data-text="No hay coincidencia"
                    return-object
                    outlined
                  />
                  <v-row>
                    <v-col>
                    <!-- :label="'Cantidad de ' + packingUnit" -->
                    </v-col>
                    <v-col>
                    <!-- :label="'Cantidad de ' + saleUnit" -->
                    </v-col>
                  </v-row>
                </th>
                <th>
                  <v-text-field
                    v-model="packingCant"
                    type="number"
                    min="1"
                    :rules="requiredFieldRules('Cantidad es requerido')"
                    outlined
                    label="Rollos"
                    required
                  />
                </th>
                <th>
                  <v-text-field
                    v-model="cant"
                    type="number"
                    min="1"
                    :rules="requiredFieldRules('Cantidad es requerido')"
                    outlined
                    label="KG"
                    required
                  />
                </th>
                <th>
                  <v-text-field
                    v-model="data.guideNumber"
                    outlined
                    name="input-7-4"
                    label="# Guía"
                    no-resize
                  />
                </th>
                <th>
                  <v-text-field
                    v-model="data.description"
                    outlined
                    name="input-7-4"
                    label="Observaciones"
                    no-resize
                  />
                </th>

                <th>
                  <v-btn
                    icon
                    class="transparent-button-icon"
                    type="submit"
                    color="success"
                    :disabled="disabledSave"
                    :loading="fetchingProducts"
                    @click="submit"
                  >
                    <v-icon size="28">
                      mdi-check-all
                    </v-icon>
                  </v-btn>
                </th>
              </tr>
            </template>

            <template v-slot:top>
              <v-row>
                <v-col cols="10">
                  <inputs-table-top
                    :code="code"
                    @changeCode="changeCode"
                  />
                  <v-row>
                    <v-col
                      cols="2"
                    />
                    <v-col cols="10">
                      <v-text-field
                        v-model="search"
                        append-icon="mdi-magnify"
                        label="Busca por cualquier campo"
                        full-width
                      />
                    </v-col>
                  </v-row>
                  <v-row
                    v-if="code !== 'TODAS'"
                    justify="center"
                  >
                    <v-col cols="2" />
                    <!-- <v-col cols="2">
                      <v-btn
                        block
                      >
                        hola
                      </v-btn>
                    </v-col> -->
                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="fetchingProducts"
                          color="primary"
                          class="transparent-button-icon"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="downloadPdf"
                        >
                          <v-icon size="35">
                            mdi-download
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Presione para descargar la orden de entrada</span>
                    </v-tooltip>

                    <v-tooltip
                      bottom
                    >
                      <template v-slot:activator="{ on, attrs }">
                        <v-btn
                          :disabled="fetchingProducts"
                          color="secondary"
                          class="transparent-button-icon"
                          icon
                          v-bind="attrs"
                          v-on="on"
                          @click="printPdf"
                        >
                          <v-icon size="35">
                            mdi-printer
                          </v-icon>
                        </v-btn>
                      </template>
                      <span>Presione para imprimir la orden de entrada</span>
                    </v-tooltip>
                  </v-row>
                  <!-- {{ code }} -->
                  <!-- <v-col
                    cols="2"
                  />
                  <v-col cols="10">
                    <v-text-field
                      v-model="search"
                      append-icon="mdi-magnify"
                      label="Busca por cualquier campo"
                      full-width
                      single-line
                      hide-details
                    />
                  </v-col> -->
                </v-col>
                <v-col>
                  <br>
                  <v-btn
                    :color="showAdd ? 'red' : 'orange'"
                    fab
                    large
                    class="v-btn--example"
                    @click="showAdd = !showAdd"
                  >
                    <v-icon v-if="showAdd">
                      mdi-close
                    </v-icon>
                    <v-icon v-else>
                      mdi-plus
                    </v-icon>
                  </v-btn>
                </v-col>
              </v-row>
            </template>
            <template v-slot:[`item.description`]="{ item: input }">
              <span v-if="input.description">
                {{ input.description }}
              </span>
              <span v-else>
                ...
              </span>
            </template>
            <template v-slot:[`item.total`]="{ item: input }">
              <span :style="{color: 'green'}">
                {{ input.totalP | formatPrice }}
              </span>
            </template>
            <template v-slot:[`item.tr`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.tk`]="{}">
              <span> 0 </span>
            </template>
            <template v-slot:[`item.amount`]="{}">
              <span
                :style="{ color: 'gray' }"
              >
                {{ formatPrice(item.amount) }}
              </span>
            </template>
            <template v-slot:[`item.createdAt`]="{ item: input }">
              {{ new Date(input.createdAt).getDate() }}/{{ new Date(input.createdAt).getMonth() + 1 }}/{{ new Date(input.createdAt).getFullYear() }}
            </template>
            <!-- <template v-slot:[`item.actions`]="{ item: item }">
              <inputs-actions
                :item="item"
              />
            </template> -->
          </v-data-table>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState, mapActions } from 'vuex'
  import InputsTableTop from './InputsTableTop'
  // import InputsActions from './InputsActions.vue'
  import { TYPE_TRANSACTION } from '../../../../const'
  // import NewProviderDialog from './NewProviderDialog.vue'
  // import SearchProvidersDialog from './SearchProvidersDialogs.vue'
  import { jsPDF as JsPdf } from 'jspdf'
  import 'jspdf-autotable'

  export default {
    name: 'ProductsTable',

    components: {
      InputsTableTop,
      // InputsActions,
      // NewProviderDialog,
      // SearchProvidersDialog,
    },

    filters: {
      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },
    },

    data () {
      return {
        item: {},
        code: 'TODAS',
        search: '',
        showAdd: false,
        isValidData: false,
        step: 1,
        salesUnits: ['UNIDAD', 'KG', 'M', 'LB'],
        dialog: false,
        dialogA: false,
        productSelected: null,
        cant: 20,
        packingCant: 1,
        warning: false,
        data: {
          provider: {},
          guideNumber: '',
          description: '',
          products: [],
        },
        headers: [
          {
            text: 'Fecha',
            value: 'createdAt',
          },
          {
            text: 'Código',
            value: 'code',
          },
          {
            text: 'Producto',
            value: 'productName',
          },
          {
            text: 'Proveedor',
            value: 'providerName',
          },
          {
            text: '# Guía',
            value: 'guideNumber',
          },
          {
            text: 'Total Rollos',
            value: 'packingCantP',
          },
          {
            text: 'Total KG',
            value: 'cantP',
          },
          {
            text: 'Precio unitario',
            value: 'amount',
          },
          {
            text: 'Total',
            value: 'total',
          },
          {
            text: 'Observaciones',
            value: 'description',
          },
          // {
          //   text: 'Acciones',
          //   value: 'actions',
          //   align: 'center',
          //   sortable: false,
          // },
        ],
      }
    },

    computed: {
      ...mapState([
        'user',
        'actionsParentBusiness',
        'fetchingProducts',
        'products',
        'actionWarehouse',
        'actionEnvironmentData',
        'fetchingWarehouseInputs',
        'warhouseInputs',
        'fetchingProviders',
        'providers',
      ]),

      inputs () {
        return this.code !== 'TODAS' ? this.warhouseInputs.filter(item => item.code === this.code) : this.warhouseInputs
      },

      saleUnit () {
        return this.productSelected !== null ? this.productSelected.salesUnit : '(unidad)'
      },

      packingUnit () {
        return this.productSelected !== null ? this.productSelected.packingUnit : '(empaque)'
      },

      color () {
        return this.data.provider?.name ? 'success' : 'indigo'
      },

      getProviders () {
        return this.providers.filter(item => item.enable)
      },

      getProducts () {
        // // // // // // // // // // // // // // // // // // // // // console.log('input store products', this.products)
        let res = this.products.filter(item => item.status)
        res = res.map(item => {
          item.name = item.codigo + ' - ' + item.description
          item.productId = item.productId ? item.productId : item.id
          delete item.id

          return item
        })
        return res
      },

      businessId () {
        return (this.user.businessId || this.actionsParentBusiness) || null
      },

      total () {
        let total = 0
        this.data.products.forEach((item, i) => {
          // // // // // // // // // // // // // // // // // // // // // // // // console.log(i, item)
          total = total + (item.wholesalePrices * item.cant)
        })
        return total
      },

      disabledSave () {
        let res = false
        // for (const [key] of Object.entries(this.data)) {
        //   res = this.data[key] === false || this.data[key] === null
        // }
        if (
          this.data.guideNumber === '' ||
          this.data.provider?.name === ''
        ) {
          res = true
        }

        // // // // // // // // // console.log('RES', res)
        return res
      },
    },

    methods: {
      ...mapActions([
        'fetchWarhouseInputs',
        'createWarehouseInputs',
        'fetchWarehouseInventory',
      ]),

      changeCode (v) {
        this.code = v
      },

      select (v) {
        // // // // // // // // // // // // console.log('CLIT', v)
        this.data.provider = v
      },

      addProductList () {
        if (this.productSelected && this.cant) {
          if (this.data.products.filter(item => item.productId === this.productSelected.productId).length >= 1) {
            this.data.products = this.data.products.map(item => item.productId === this.productSelected.productId ? ({
              ...item,
              cant: Number(item.cant) + Number(this.cant),
              packingCant: Number(item.packingCant) + Number(this.packingCant),
            }) : item)
          } else {
            this.data.products.push({ ...this.productSelected, cant: this.cant, packingCant: this.packingCant })
          }
        }

        this.productSelected = null
        this.cant = 1
        this.packingCant = 1
      },

      deleteProductList (id) {
        this.data.products = this.data.products.filter(item => item.productId !== id)
      },

      requiredFieldRules (text) {
        return [
          v => !!v || text,
        ]
      },
      resetData () {
        // for (const [key] of Object.entries(this.data)) {
        //   this.data[key] = '' // Making all keys of the object ''
        // }
        this.data = {
          provider: {},
          guideNumber: '',
          description: '',
          products: [],
        }
      },
      async afterCreate () {
        this.$emit('close')
        this.showAdd = false
        this.resetData()
        await this.fetchWarhouseInputs({ id: this.actionWarehouse })
        await this.fetchWarehouseInventory({ id: this.actionWarehouse })
        // await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      },
      async submit (e) {
        e.preventDefault()
        // const businessId = this.businessId
        const total = this.total
        if (
          // this.isValidData &&
          this.businessId !== null &&
          this.disabledSave === false
        ) {
          // const submit = {

          // }

          const submit = {
            ...this.data,
            products: [{ ...this.productSelected, cant: this.cant, packingCant: this.packingCant }],
            typeTransactionId: TYPE_TRANSACTION.entrada,
            userId: this.user.id,
            warehouseId: this.actionWarehouse,
            relatedWarehouseId: null,
            total: total,
            code: this.actionEnvironmentData.code,
          }

          await this.createWarehouseInputs(submit)
          this.afterCreate()
        } else {
          this.warning = true
          setInterval(() => {
            this.warning = false
          }, 5000)
        }
      },

      async genPdf (print = false) {
        this.item = this.inputs[0]
        const doc = new JsPdf({
          orientation: 'portrait',
          format: 'letter',
        })
        // const img = await this.getImageByUrl('/logo.png') -> IMAGEN QUE QUITARON

        // // // // // console.log('ITEM', this.item)
        // // // // // // // // // // // // // // // // // // // // console.log('DETAIL', this.warhouseInputsDetail)

        // doc.addImage(img, 'PNG', 10, 5, 100, 20, 'logo')
        doc.setFontSize(38).text('Sistema Genérico', 10, 23)
        doc.setTextColor(255, 0, 0)
        doc.setFontSize(18).text(`CÓDIGO: ${this.item.code}`, 130, 20)
        doc.setTextColor(0, 0, 0)
        doc.setFontSize(12).text(`Fecha de emisión: ${this.formatDate(this.item.createdAt)}`, 130, 27)
        doc.setFontSize(14).text(`Número de guía: ${this.item.guideNumber}`, 130, 35)

        doc.setTextColor(0, 0, 0).text('Proveedor: ', 10, 45)
        doc.setTextColor(100, 100, 100).text(this.item.providerName || '', 35, 45)

        // doc.setTextColor(0, 0, 0).text('C.I / RIF: ', 130, 65)
        // doc.setTextColor(100, 100, 100).text(this.clientsSearch?.ci || '', 150, 65)
        // // // // // // // // // // // console.log('list', this.item.providerAddress, this.item.providerAddress.length)

        doc.setTextColor(0, 0, 0).text('Dirección: ', 10, 53)
        doc.setTextColor(100, 100, 100).text(this.item.providerAddress ? this.item.providerAddress.slice(0, 105) : '', 33, 53, {
          maxWidth: 170,
        })

        doc.setTextColor(0, 0, 0).text('Teléfono: ', 10, 65)
        doc.setTextColor(100, 100, 100).text(this.item.providerPhone || '', 32, 65)

        doc.setTextColor(0, 0, 0).text('Email: ', 80, 65)
        doc.setTextColor(100, 100, 100).text(this.item.providerEmail || '', 95, 65)

        doc.setTextColor(0, 0, 0)
        doc.setFontSize(18).setTextColor(0, 0, 0).text('Nota de entrada', 82, 90)

        doc.autoTable({
          columns: [
            { title: 'Codigo del producto', dataKey: 'code' },
            { title: 'Nombre del producto', dataKey: 'productName' },
            { title: 'Rollos', dataKey: 'packingCantP' },
            // { title: 'Unidad de empaque', dataKey: 'packingUnit' },
            { title: 'KG', dataKey: 'cant' },
            { title: 'Precio Unitario', dataKey: 'amount_total' },
            { title: 'Precio Total', dataKey: 'total' },
            // { title: 'Unidad', dataKey: 'salesUnit' },
          ],
          body: this.inputs.map(item => {
            return {
              ...item,
              cant: Math.abs(item.cantP),
              total: this.formatPrice(Math.abs(item.cantP) * item.amount),
              amount_total: this.formatPrice(item.amount),
            }
          }),
          margin: {
            left: 10,
            top: 95,
          },
        })
        doc.setLineWidth(0.5).line(0, doc.internal.pageSize.height - 30, doc.internal.pageSize.width, doc.internal.pageSize.height - 30)
        doc.setFontSize(10)
        doc.text('Observaciones:', 10, doc.internal.pageSize.height - 25)
        doc.setFontSize(8)
        doc.text(this.item.description, 10, doc.internal.pageSize.height - 20)
        doc.setFontSize(15)
        doc.text(`Total: ${this.formatPrice(this.item.totalAmountCurrencyReference)}`, doc.internal.pageSize.width - 55, doc.internal.pageSize.height - 10)

        // doc.autoPrint(),
        // doc.output('orden')
        // doc.autoPrint()
        // This is a key for printing
        // doc.output('dataurlnewwindow')

        if (print) {
          doc.autoPrint()
          doc.output('dataurlnewwindow')
        } else {
          doc.save('nota-de-entrada.pdf')
        }
      },

      formatPrice (v, type = 'USD') {
        return new Intl.NumberFormat('en-ve', { style: 'currency', currency: type, minimumFractionDigits: 2 }).format(v)
      },

      formatDate (dateProp) {
        const date = new Date(dateProp)

        const year = date.getFullYear()
        const month = date.getMonth() + 1
        const day = new Date(year, month, date.getDate() + 1).getDate()

        if (month < 10) {
          return (`${day}/0${month}/${year}`)
        } else {
          return (`${day}/${month}/${year}`)
        }
      },

      async downloadPdf () {
        // await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.genPdf()
      },

      async printPdf () {
        // await this.fetchWarhouseInputsDetail({ id: this.item.id })
        await this.genPdf(true)
      },
    },
  }
</script>

<style scoped>
  .transparent-button-icon {
    background-color: transparent !important;
  }
</style>
