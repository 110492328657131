<template>
  <products-table />
</template>

<script>
  import { mapActions, mapState, mapMutations } from 'vuex'
  import ProductsTable from '../components/warehouses-inputs/InputsTable.vue'
  export default {
    name: 'WarehousesInputs',
    components: {
      ProductsTable,
    },
    computed: {
      ...mapState(['user', 'actionsParentBusiness', 'actionWarehouse']),
    },

    async created () {
      this.setActions(Number(this.$route.params.id))
      this.setEnvironment('warehouse')
      await this.fetchWarhouseInputs({ id: this.$route.params.id || this.actionWarehouse })
      await this.fetchProducts({ id: this.user.businessId || this.actionsParentBusiness })
      await this.fetchProviders()
      // await this.fetchProductLine({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductClassification({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductCategory({ id: this.user.businessId || this.actionsParentBusiness })
      // await this.fetchProductType({ id: this.user.businessId || this.actionsParentBusiness })
    },

    methods: {

      ...mapMutations({
        setEnvironment: 'SET_ACTIONS_ENVIRONMENT',
        setActions: 'SET_ACTIONS_WAREHOUSE',
      }),
      ...mapActions([
        'fetchProducts',
        'fetchWarhouseInputs',
        'fetchProviders',
      ]),
      // ...mapActions([
      //   'fetchProductLine',
      //   'fetchProductClassification',
      //   'fetchProductCategory',
      //   'fetchProductType',
      // ]),
    },
  }
</script>
